

.triDivSearch{
  position: absolute;
  right: 50px;
  top: 220px;
  z-index: 9999999999999;

 
}
.allProductsearch {
  
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
