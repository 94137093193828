.listOfArticle{
    text-align: center;
    font-size: 12px;
    word-break: keep-all;
    position: absolute;
    z-index: 99999;
    border-radius: 4px;
    width: auto;
    background-color: #FFF;
}
.liArticle{
    background-color: #FFF;
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    font-family: 'Nunito';
    padding:5px;
}
.listDetails{
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-around;
    margin-left: 10px;
    height: auto;
    padding: 5px;
}
.listDetails>span{
    margin-left: 10px;
}
.prodPicList{
    width: 75px;
}
.listLink{
    text-decoration: none;
    transition: all 2s;
    
}
.listLink:hover{
    text-decoration: underline;
    color: #F7F7F7;
}
@media only screen and (max-width: 700px) {
    .listDetails>span{
        font-size: 11px;
        line-height: 16px;
        min-height: 24px;
      
    }
}