
  .slideNew {
    width: 100vw;
    margin-top: 50px;
  }
  .bigTitleSlide{
    font-family: 'Magie';
    letter-spacing: 1px;
    font-size: 32px;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 1200px) {

  
    .slideNew {
      width: 100vw;
      margin-top: 50px;
    }
    .bigTitleSlide{
      font-family: 'Magie';
      letter-spacing: 1px;
      font-size: 32px;
      text-transform: uppercase;
    }
  }
  @media only screen and (max-width: 700px) {
   
    .slideNew {
      width: 100vw;
      margin-top: 50px;
      overflow-x: hidden;
    }
    .bigTitleSlide{
      font-family: 'Magie';
      letter-spacing: 1px;
      font-size: 24px;
      text-transform: uppercase;
    }
  }
  