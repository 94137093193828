.topBtn{
  display: inline-block;
    position: fixed;
    bottom: 20px;
    right: 2px;
    z-index: 999999999;
    cursor: pointer;
    padding: 0px 1px;
    border-radius: 50%;
    animation: float 2s ease-in-out infinite;
    border: 1px solid #af7c80;
  }
  .topBtn.hidden {
    display: none;
  }
  
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  
  @media screen and (max-width:700px) {
    .topBtn{
      display: inline-block;
        position: fixed;
        bottom: 20px;
        right:5px;
        z-index: 999999999;
        cursor: pointer;
        padding: 0px 2px;
        border-radius: 50%;
        animation: float 2s ease-in-out infinite;
        border: 1px solid #af7c80;
      }
  }