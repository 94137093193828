.header {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.25);
  z-index: 9999999999999;
  animation: load 1s ease-in-out;
  background-color: white;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logoContainer {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 4px;
  height: 80px;
}

.navbarBottom {
  background-color: var(--secondColor);
  padding: 2px;
}

.userDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80vw;
}

.searchListDiv {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-family: "Magie";
}

.searchList {
  width: 400px;
}

.logo {
  width: 200px;
  height: auto;
  margin: 20px 40px;
  animation: pulse 4s linear;
  transform: scale(1.15);
}

.DialogDiv {
  background-color: var(--firstColor);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Exo";
}

.navbarIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin-left: 10%;
}
.iconContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.iconContainer > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  border-radius: 4px;
  transition: all 0.7s;
  text-transform: uppercase;
}
.iconContainer a:hover {
  background-color: #af7c80;
}

.iconContainer > a > span {
  font-family: "Nunito";
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px;
}
.custom-menu {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: #859e96;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.menu-content {
  display: block;
  position: absolute;
  background-color: #859e96;
  color: #fff;
  width: 150px;
  top: 100%;
  left: -2px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 9999999999;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  padding: 4px 0;
  font-family: "Exo";
  text-transform: uppercase;
}

.menu-content a {
  color: #fff;
  text-decoration: none;
  display: block;
  transition: all 0.6s;
}

.menu-content a:hover {
  background-color: #af7c80;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.15);
    opacity: 1;
  }
}

.linkMenu {
  display: none;
  position: absolute;
  top: -15px;
  right: 0;
  background-color: white;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 999999;
}

.linkMenu.show {
  transform: translateX(0);
}

.linkItems {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.header-Btn {
  font-family: "Nunito" !important;
  font-size: 14px !important;
  margin: 4px !important;
  font-weight: 600 !important;
  letter-spacing: 1px  !important;
}
@media only screen and (max-width: 1200px) {

  
  .logoContainer {
    position: relative;
    width: 100%;
    height: 100px;
    overflow: hidden;
  }
  
  .navbar {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 4px;
    height: 80px;
  }
  
  .navbarBottom {
    background-color: var(--secondColor);
    padding: 2px;
  }

  
  .searchListDiv {
    display: flex;
    flex-direction: column;
    width: 300px;
    font-family: "Magie";
  }
  
  .searchList {
    width: 300px;
  }
  
  .logo {
    width: 180px;
    height: auto;
    margin: 20px 40px;
  }
  
  .DialogDiv {
    background-color: var(--firstColor);
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: "Exo";
  }
  
  .iconImg {
    width: 60px;
    cursor: pointer;
    transition: transform 0.6s;
  }
  
  .iconImg:hover {
    transform: translateY(-5px);
  }
  .navbarIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 95%;
    margin-left: 2.5%;
  }
  .iconContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  
  .iconContainer > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    border-radius: 4px;
    transition: all 0.7s;
  }
  .iconContainer a:hover {
    background-color: #af7c80;
  }
  
  .iconContainer > a > span {
    font-family: "Nunito";
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px;
  }
  .custom-menu {
    position: relative;
    display: inline-block;
  }
  
  .menu-button {
    background-color: #859e96;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .menu-content {
    display: block;
    position: absolute;
    background-color: #859e96;
    color: #fff;
    width: 150px;
    top: 100%;
    left: -2px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 9999999999;
  }
  
  .menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-content li {
    padding: 4px 0;
    font-family: "Exo";
  }
  
  .menu-content a {
    color: #fff;
    text-decoration: none;
    display: block;
    transition: all 0.6s;
  }
  
  .menu-content a:hover {
    background-color: #af7c80;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1.15);
      opacity: 1;
    }
  }
  
  .linkMenu {
    display: none;
    position: absolute;
    top: -15px;
    right: 0;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 999999;
  }
  
  .linkMenu.show {
    transform: translateX(0);
  }
  
  .linkItems {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .header-Btn {
    font-family: "Nunito" !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 700px) {
  .navbarBottom {
    display:none;
  }
  .menu-button{
    display: none;
  }
  .logoDiv {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .navbar {
    flex-direction: column;
    height: auto;
  }
  .searchListDiv {
    width: 230px;
    margin-left: -20px;
  }
  .searchList {
    width: 230px;
  }
  .logo {
    width: 150px;
    margin: -20px 10px;
  }
  .navbarIcon {
    display: none;
  }
  .linkMenu {
    height: auto;
    min-height: 105vh;
    overflow-y: hidden;
    width: 100%;
    overflow-x: hidden;
    position:fixed;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--firstColor);
  }

  .iconImg {
    width: 50px;
    margin: 0px 4px;
    padding-right: 25%;
  }
  .iconImg.home {
    width: 40px;
    margin: 0px 4px;
    padding-right: 25%;
  }
  .linkMenu li {
    margin-top: 2px;
    list-style: none;
    margin-left: 0vw;
  }
  .iconContainer {
    margin-top: 1px;
    width: 100%;
   
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iconContainer > a {
    padding: 2px 4px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 4vh;
    padding-left: 25%;
    width: 100vw;
    margin-left: -25%;
    background-color: white;
    color: #af7c80;
  }
  .iconContainer > a > span {
    font-family: "magie";
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: -25px;
  }
  .iconContainer a:hover {
    background-color: #fff;
  }
  .socialMediaIcon {
    padding-top: 20px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0px;
  }
  .socialMediaIcon > h3 {
    text-transform: uppercase;
    font-family: "Magie";
    font-size: 14px;
    color: #283943;
  }
  .userDiv{
    margin-bottom: 20px;
  }

}
