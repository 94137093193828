.navbarCollection.details {
  width: 100%;
}
.loader.details {
  margin-left: 40%;
  margin-top: 10%;
}

.productcardDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.productDetails {
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-bottom: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.morecardDetailP {
  margin-left: 50px;
  width: 40%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.cardDetails {
  width: 500px;
  border-radius: 2px;
  z-index: 9999;
}
.morecardDetailP > h2 {
  width: 100%;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Exo";
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  color: black;
}
.morecardDetailP > h4 {
  width: 100%;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Exo";
  color: var(--thirdColor);
}
.morecardDetailP > p {
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 5px;
  font-family: "Poppins";
  color: black;
}
.bestProductDiv {
  padding: 10px;
}
.bestProductDiv > h2 {
  width: 100%;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Exo";
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  color: black;
}

.profitDetail {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px rgba(0, 0, 0, 0.089) solid;
  display: flex;
  flex-direction: column;
}
.profitDetail .info {
  font-family: "Nunito";
  color: var(--thirdColor);
}
.profitDetailspan {
  font-family: "Nunito";
  font-size: 14px;
}
.profitDetailspan>a {
  text-decoration: none;
}
.morecardDetailPrice {
  color: rgb(53, 53, 53);
  font-size: 12px;
}
.numberArticle{
  font-size: 14px;
  font-family: 'Nunito';
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .navbarCollection.details {
    width: 100%;
  }
  .loader.details {
    margin-left: 40%;
    margin-top: 10%;
  }
  
  .productcardDetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .productDetails {
    width: 90%;
    margin-left: 5%;
    height: auto;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .morecardDetailP {
    margin-left: 40px;
    width: 60%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .cardDetails {
    width: 350px;
    border-radius: 2px;
    z-index: 9999;
  }
  .morecardDetailP > h2 {
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
  .morecardDetailP > h4 {
    width: 100%;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    color: var(--thirdColor);
  }
  .morecardDetailP > p {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 5px;
    font-family: "Poppins";
    color: black;
  }
  .bestProductDiv {
    padding: 10px;
  }
  .bestProductDiv > h2 {
    width: 100%;
    font-size: 20px;
    margin-left: 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
  
  .profitDetail {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px rgba(0, 0, 0, 0.089) solid;
    display: flex;
    flex-direction: column;
  }
  .profitDetail .info {
    font-family: "Nunito";
    color: var(--thirdColor);
  }
  .profitDetailspan {
    font-family: "Nunito";
    font-size: 14px;
  }
  .profitDetailspan>a {
    text-decoration: none;
  }
}
@media screen and (max-width: 700px) {
  .navbarCollection.details {
    width: 100%;
  }
  .loader.details {
    margin-left: 40%;
    margin-top: 10%;
  }
  
  .productcardDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .productDetails {
    width: 90%;
    margin-left: 5%;
    height: auto;
    margin-bottom: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .morecardDetailP {
    margin-left: 0px;
    width: 80%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .cardDetails {
    width: 250px;
    border-radius: 2px;
    z-index: 9999;
  }
  .morecardDetailP > h2 {
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
  .morecardDetailP > h4 {
    width: 100%;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    color: var(--thirdColor);
  }
  .morecardDetailP > p {
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 5px;
    font-family: "Poppins";
    color: black;
  }
  .bestProductDiv {
    padding: 10px;
  }
  .bestProductDiv > h2 {
    width: 100%;
    font-size: 14px;
    margin-left: 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
  
  .profitDetail {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px rgba(0, 0, 0, 0.089) solid;
    display: flex;
    flex-direction: column;
  }
  .profitDetail .info {
    font-family: "Nunito";
    color: var(--thirdColor);
  }
  .profitDetailspan {
    font-family: "Nunito";
    font-size: 14px;
  }
  .profitDetailspan>a {
    text-decoration: none;
  }
  .navbarCollection.details{
    width: 80%;
  }
}
