.products {
  display: flex;
  flex-direction: column;
}
.productCouverture{
  height: 60vh;
  object-fit: cover;
  object-position: 50%;
}
.slideText {
  width: 100vw;
  height: 60vh;
  clip-path: inset(0em);
  background-size: cover;
  background-position: center;
  text-align: center;
  transition: all 0.3s ease;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Magie";
  letter-spacing: 2px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.slideContainer:hover .slideText {
  opacity: 1;
}

.slideText > div {
  width: 100%;
  height: 100%;
  padding: 25%;
  font-size: 24px;
  clip-path: inset(0em);
  border-radius: 4px;
  background-color: #00000035;
  background-size: cover;
  background-position: center;
}

.slideText > div > p {
 font-family:'Magie';
 font-weight: 800;
 font-size: 50px;
 letter-spacing: 4px;
}
.navbarCollection{
 text-align: left;
  margin-left: 50px;
  font-family: 'Exo';
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}
.navbarCollection>a{
  text-decoration: none;
  color: var(--firstColor);
  font-weight: 500;
  transition: all 0.6s;
}
.navbarCollection>a:hover{
  text-decoration: none;
  color: var(--firstColor);
  font-weight: 600;
}
.product {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.productContain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.pathTitle {
  width: 90%;
  margin-left: 20px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Nunito";
  padding: 4px 20px;
  border-radius: 2px;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  color: black;
}
@media screen and (max-width: 1200px) {
  .products {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .nav {
    margin-top: 20px;
    position: relative;
  }
  .product {
    width: 100vw;
    height: auto;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .productContain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 15px;
  }
  
  .pathTitle {
    width: 100%;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Poppins";
    padding: 4px 20px;
    border-radius: 2px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
  .productCouverture{
    height: 50vh;
    object-fit: cover;
    object-position: 50%;
  }
  .slideText {
    width: 100vw;
    height: 50vh;
    letter-spacing: 2px;
  }
  
  .slideContainer:hover .slideText {
    opacity: 1;
  }
  
  .slideText > div {
    width: 100%;
    height: 100%;
    padding: 25%;
    font-size: 24px;
    clip-path: inset(0em);
    border-radius: 4px;
    background-color: #00000035;
    background-size: cover;
    background-position: center;
  }
  
  .slideText > div > p {
   font-family:'Magie';
   font-weight: 800;
   font-size: 50px;
   letter-spacing: 4px;
  }
}
@media screen and (max-width: 700px) {
  .products {
    width: 100%;
    margin-left: 0px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
    margin-top: 5px;
  }
  .nav {
    width: auto;
    margin-left: 5vw;
    margin-top: 20px;
    position: relative;
  }
  .product {
    width: 100vw;
    height: auto;
    margin-bottom: 15vh;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .productContain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  
  .pathTitle {
    width: 90%;
    margin-left: -10px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Poppins";
    padding: 4px 20px;
    border-radius: 2px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
  .productCouverture{
    height: 300px;
    object-fit: cover;
    object-position: 50%;
  }
  .slideText {
    width: 100vw;
    height: 300px;
    letter-spacing: 2px;
  }
  
  .slideContainer:hover .slideText {
    opacity: 1;
  }
  
  .slideText > div {
    width: 100%;
    height: 100%;
    padding: 25%;
    font-size: 24px;
    clip-path: inset(0em);
    border-radius: 4px;
    background-color: #00000035;
    background-size: cover;
    background-position: center;
  }
  
  .slideText > div > p {
   font-family:'Magie';
   font-weight: 800;
   font-size: 50px;
   letter-spacing: 4px;
  }
}
