.hidden {
    display: none; 
  }
.articleDetailePlus{
  position: absolute;
  bottom: -5px;
  left: 0;
}

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(255, 177, 66, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
  }
  .card {
    position: relative;
  }
  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
.cardMedia{
  width: 280px;
  margin-top: -50px;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMedia:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.082);
}

.cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMediaDialog:hover {
  transform: scale(1.1);
}
.articleDetaile{
  font-family: 'Exo';
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 10px;
  letter-spacing: 1px;
  color: var(--firstColor);
}
.articleDetaile2{
  font-family: 'Exo';
  
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 1px;
}
  @media screen and (max-width:1200px) {
    .hidden {
      display: none; 
    }
  .articleDetailePlus{
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
    .card {
      position: relative;
    }
    .cardMediaLink{
      text-decoration: none;
      text-align: center;
    }
  .cardMedia{
    width: 220px;
    margin-top: -50px;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMedia:hover {
    transform: scale(1.1);
    color: rgba(0, 0, 0, 0.082);
  }
  
  .cardMediaDialog{
    padding: 50px;
    width: 80%;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMediaDialog:hover {
    transform: scale(1.1);
  }
  .articleDetaile{
    font-family: 'Exo';
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 1px;
    color: var(--firstColor);
  }
  .articleDetaile2{
    font-family: 'Exo';
    
    font-size: 13px;
    margin-top: 10px;
    letter-spacing: 1px;
  }
}  
@media screen and (max-width:700px) {
  .articleDetailePlus{
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
    .card {
      position: relative;
    }
    .cardMediaLink{
      text-decoration: none;
      text-align: center;
    }
  .cardMedia{
    width: 160px;
    margin-top: -30px;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMedia:hover {
    transform: scale(1.1);
    color: rgba(0, 0, 0, 0.082);
  }
  
  .cardMediaDialog{
    padding: 50px;
    width: 80%;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMediaDialog:hover {
    transform: scale(1.1);
  }
  .articleDetaile{
    font-family: 'Exo';
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
    letter-spacing: 1px;
    color: var(--firstColor);
  }
  .articleDetaile2{
    font-family: 'Exo';
    
    font-size: 10px;
    margin-top: 10px;
    letter-spacing: 1px;
  }
}  