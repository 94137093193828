.allProduct {
  margin-top: 50px;
  margin-bottom: 50px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.loader {
  width: 100px;
  height: 100px;
  margin-top: 100px;
  border-radius: 50%;
  margin-left: 45%;
  border: 3px solid transparent;
  border-top-color: var(--secondColor);
  border-bottom-color: var(--secondColor);
  border-right-color: var(--firstColor);
  border-left-color: var(--firstColor);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
.triDiv{
  position: absolute;
  right: 100px;
  top: 20px;
}

.pagination {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: -60px;
  margin-bottom: 60px;
  z-index: 111;
}
@media screen and (max-width: 1200px) {
  .allProduct {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .pagination {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -60px;
    margin-bottom: 60px;
    z-index: 111;
  }
  .triDiv{
    position: absolute;
    right: 40px;
    top: 20px;
  }
}
@media screen and (max-width: 700px) {
  .allProduct {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }
  .loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 40%;
    margin-top: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  .pagination {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -60px;
    margin-bottom: 60px;
    z-index: 111;
  }
  .triDiv{
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    flex-direction: column;
  }
}
