.infoDescreption {
  width: 100vw;
  text-align: center;
}
.info{
  margin-top: 100px;
  width: 100vw;
}
.infoHeader{
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color:#af7c8029;
}
.infoHeader>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
.infoHeader>div>h1{
  font-family: 'Magie';
}
.infoHeader>div>p{
  font-family: 'Nunito';
  font-size: 18px;
  width: 80%;
}
.infoPic {
  width: 30%;
}
.infoMap {
  margin-top: 100px;
  width: 500px;
  height: 300px;
}
.infoDetails {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.infoDetails > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid black;
  padding: 20px;
}
.infoDetails > div > h4 {
  text-transform: uppercase;
  font-family: 'Exo';
  font-size: 18px;
  padding: 4px;
  margin-top: -35px;
  background-color: #fff;
}
.infoDetails > div > a {
  text-decoration: none;
  color: black;
  font-family: "Nunito";
  font-size: 16px;
}
@media only screen and (max-width: 1200px) {
  .infoDescreption {
    width: 100vw;
    text-align: center;
  }
  .info{
    margin-top: 100px;
    width: 100vw;
  }
  .infoHeader{
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color:#af7c8029;
  }
  .infoHeader>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
  .infoHeader>div>h1{
    font-family: 'Magie';
    font-size: 24px;
  }
  .infoHeader>div>p{
    font-family: 'Nunito';
    font-size: 16px;
    width: 80%;
  }
  .infoPic {
    width: 30%;
  }
  .infoMap {
    margin-top: 50px;
    width: 500px;
    height: 300px;
  }
  .infoDetails {
    margin-top: 70px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .infoDetails > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid black;
    padding: 20px;
  }
  .infoDetails > div > h4 {
    text-transform: uppercase;
    font-family: 'Exo';
    font-size: 18px;
    padding: 4px;
    margin-top: -35px;
    background-color: #fff;
  }
  .infoDetails > div > a {
    text-decoration: none;
    color: black;
    font-family: "Nunito";
    font-size: 16px;
  }
}
@media only screen and (max-width: 700px) {
  .infoDescreption {
    width: 100vw;
    text-align: center;
  }
  .info{
    margin-top: 100px;
    width: 100vw;
  }
  .infoHeader{
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color:#af7c8029;
  }
  .infoHeader>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
  }
  .infoHeader>div>h1{
    font-family: 'Magie';
    font-size: 18px;
  }
  .infoHeader>div>p{
    font-family: 'Nunito';
    font-size: 12px;
    width: 95%;
  }
  .infoPic {
    width: 60%;
    margin-top: 10px;
  }
  .infoMap {
    margin-top: 50px;
    width: 95%;
    height: auto;
  }
  .infoDetails {
    margin-top: 70px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .infoDetails > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid black;
    padding: 20px;
  }
  .infoDetails > div > h4 {
    text-transform: uppercase;
    font-family: 'Exo';
    font-size: 16px;
    padding: 4px;
    margin-top: -35px;
    background-color: #fff;
  }
  .infoDetails > div > a {
    text-decoration: none;
    color: black;
    font-family: "Nunito";
    font-size: 14px;
  }
}
