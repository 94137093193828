.hidden {
    display: none; 
  }
.articleDetailePlus{
  position: absolute;
  bottom: -5px;
  left: 0;
}

  .cardNew {
    position: relative;
    padding: 20px;
    margin-left: -8px;
    transition: transform 0.8s;
  }
  .cardNew:hover {
    transform: scale(1.02);
  }
  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
.cardMediaNew{
  width: 200px;
  margin-top: -20px;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMediaNew:hover {
  transform: scale(1.3);
  color: rgba(0, 0, 0, 0.082);
}

.cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMediaDialog:hover {
  transform: scale(1.1);
}
.articleDetaileNew{
  font-family: 'Nunito';
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
  @media screen and (max-width:1200px) {
    .hidden {
      display: none; 
    }
  .articleDetailePlus{
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
    .cardNew {
      position: relative;
      padding: 20px;
      margin-left: -8px;
      transition: transform 0.8s;
    }
    .cardNew:hover {
      transform: scale(1.02);
    }
    .cardMediaLink{
      text-decoration: none;
      text-align: center;
    }
  .cardMediaNew{
    width: 180px;
    margin-top: -10px;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMediaNew:hover {
    transform: scale(1.3);
    color: rgba(0, 0, 0, 0.082);
  }
  
  .cardMediaDialog{
    padding: 50px;
    width: 80%;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMediaDialog:hover {
    transform: scale(1.1);
  }
  .articleDetaileNew{
    font-family: 'Nunito';
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    margin-top: 5px;
  }
}  
@media screen and (max-width:700px) {
  .hidden {
    display: none; 
  }

  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
  .cardMediaNew{
    width: 120px;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  

.cardMedia:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.082);
}

.cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMediaDialog:hover {
  transform: scale(1.1);
}
.articleDetaileNew{
  font-family: 'Nunito';
  text-transform: uppercase;
  font-size: 9px;
  margin-top: 5px;
}
}  