.home {
  margin-top: 1px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  z-index: 99;
  animation: load 2s ease-in-out;
}
.carouselHome {
  width: 100vw;
  height: 70vh;
}

.carouselHome img {
  width: 100vw;
  height: 70vh;
  border-radius: 4px;
  z-index: 99;
  object-fit: cover;
  object-position: 50%;
}

.slick-slider {
  position: relative;
}
.slick-dots {
  background-color: #af7c80be;
  border-radius: 4px;
  bottom: 4px;
  left: 50%;
  width: 200px;
  height: 30px;
  transform: translateX(-50%);
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  position: absolute;
}
.dotsUl {
  margin: 0;
  position: relative;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
}
.slick-dots li button:before {
  color: white !important;
  font-size: 100px !important;
  margin-top: -40px;
  content: ".";
}
.descreption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 100px;
  width: 90vw;
  margin-left: 5vw;
  background-color: #af7c800a;
  padding: 45px 0;
  border-radius: 500px;
}
.logoHome {
  width: auto;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  margin-top: -50px;
}
.descreption > p {
  font-family: "Nunito";
  font-size: 20px;
  width: 80%;
}
.descreption > p > span {
  font-family: "Magie";
  font-size: 32px;
  letter-spacing: 1px;
  font-weight: 600;
  width: 80%;
}
.coffretHome{
  margin-top: 50px;
  width: 80vw;
  margin-left: 10vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
justify-content: space-around;
  align-items: center;
  background-color: #859e9630;
  border-radius: 4px;
}
.coffretHome>img{
  width: 30%;
  margin-left: 0%;
  border-radius: 4px;
}
.coffretHome>div{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.coffretHome>div>h1{
 font-family: 'Magie';
 letter-spacing: 1px;
}
.coffretHome>div>p{
  font-family: 'Nunito';
 }
@media screen and (max-width: 1200px) {
  .home {
    margin-top: 4px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    z-index: 99;
    animation: load 2s ease-in-out;
  }
  .carouselHome {
    width: 100vw;
    height: 50vh;
  }
  
  .carouselHome img {
    width: 100vw;
    height: 50vh;
    border-radius: 4px;
    z-index: 99;
    object-fit: cover;
    object-position: 50%;
  }
  
  .slick-slider {
    position: relative;
  }
  .slick-dots {
    background-color: #af7c80be;
    border-radius: 4px;
    bottom: 4px;
    left: 50%;
    width: 200px;
    height: 30px;
    transform: translateX(-50%);
    display: flex !important;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    position: absolute;
  }
  .dotsUl {
    margin: 0;
    position: relative;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
  }
  .slick-dots li button:before {
    color: white !important;
    font-size: 100px !important;
    margin-top: -40px;
    content: ".";
  }
  .descreption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
    width: 95vw;
    margin-left: 2.5vw;
    background-color: #af7c800a;
    padding: 45px 0;
    border-radius: 500px;
  }
  .logoHome {
    width: auto;
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    margin-top: -50px;
  }
  .descreption > p {
    font-family: "Nunito";
    font-size: 20px;
    width: 80%;
  }
  .descreption > p > span {
    font-family: "Magie";
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: 600;
    width: 80%;
  }
  .coffretHome{
    margin-top: 50px;
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  justify-content: space-around;
    align-items: center;
    background-color: #859e9630;
    border-radius: 4px;
  }
  .coffretHome>img{
    width: 30%;
    margin-left: 0%;
    border-radius: 4px;
  }
  .coffretHome>div{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .coffretHome>div>h1{
   font-family: 'Magie';
   letter-spacing: 1px;
   font-size: 22px;
  }
  .coffretHome>div>p{
    font-family: 'Nunito';
   }
}
@media screen and (max-width: 700px) {

  .carouselHome {
    width: 100vw;
    height: 300px;
  }
  
  .carouselHome img {
    width: 100vw;
    height: 300px;
    border-radius: 4px;
    z-index: 99;
    object-fit: cover;
    object-position: 50%;
  }
  
  .slick-slider {
    position: relative;
  }
  .slick-dots {
   
    border-radius: 4px;
    bottom: 4px;
    left: 50%;
    width: 200px;
    height: 20px;
    transform: translateX(-50%);
    display: flex !important;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    position: absolute;
  }
  .dotsUl {
    margin: 0;
    position: relative;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
  }
  .slick-dots li button:before {
    color: white !important;
    font-size: 100px !important;
    margin-top: -40px;
    content: ".";
  }
  .descreption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
    width: 98vw;
    margin-left: 1vw;
    background-color: #af7c800a;
    padding: 25px 0;
    border-radius: 500px;
  }
  .logoHome {
    width: auto;
    position: absolute;
    width: 90vw;
    z-index: -1;
    opacity: 0.2;
    margin-top: -50px;
  }
  .descreption > p {
    font-family: "Nunito";
    font-size: 14px;
    width: 90%;
  }
  .descreption > p > span {
    font-family: "Magie";
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 600;
    width: 90%;
  }
  .coffretHome{
    margin-top: 50px;
    width: 95vw;
    margin-left: 2.5vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  justify-content: space-around;
    align-items: center;
    background-color: #859e9630;
    border-radius: 4px;
  }
  .coffretHome>img{
    width: 30%;
    margin-left: 0%;
    border-radius: 4px;
  }
  .coffretHome>div{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .coffretHome>div>h1{
   font-family: 'Magie';
   letter-spacing: 0px;
   font-size: 16px;
  }
  .coffretHome>div>p{
    font-family: 'Nunito';
    font-size: 11px;
   }
}
